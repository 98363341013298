<template>
    <div class="page">
        <div class="cont-wrap">
            <img class="top-img" src="../assets/gy_bg00.png" alt="" srcset="" />
            <div class="content">
                <div class="gy-wrap" v-for="i in 7" :key="i">
                    <div class="gy-cont">
                        <span class="gy-name">张现</span>
                        <div class="gy-info">
                            <ul>
                                <li>2021公益达人</li>
                                <li>2019温州中小企业联合“爱心大使” </li>
                                <li>2017温州全民投票选“控烟形象大使”</li>
                            </ul>
                        </div>
                    </div>
                    <div class="gy-avatar">
                        <img src="../assets/gy_mod.jpg" alt="" srcset="">
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.page {
  background: url(../assets/gy_bg01.jpg) no-repeat center top / 100% auto;
  padding-top: 97vw;
  background-color: #c0dc54;
}
.cont-wrap {
  font-size: 0;
  margin: 12px;
  padding-bottom: 30px;
}
.content {
  padding: 1px 0;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 0 3px #ccc;
}

.gy-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d8e7b4;
  border-radius: 12px;
  margin:25px 12px ;
  box-shadow: 0 0 6px #999;
  background-image: url(../assets/gy_bg_inner.jpg);
  background-size: auto 36%;
  background-position: 66% center;
  background-repeat: no-repeat;
}
.content>div.gy-wrap:nth-child(2n){
    flex-direction: row-reverse;
    background-position: 34% center;
}
.gy-name {
  display: inline-block;
  font-size: 15px;
  padding: 3px 15px;
  background-color: #5f782b;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 10px;
}
.gy-cont {
  box-sizing: border-box;
  padding: 12px;
  width: calc(100% - 30vw);
  color: #5f782b;
}
.gy-info {
  font-size: 12px;
  text-shadow: 0 0 1px #ccc;
}
.gy-info ul {
  margin: 0;
  padding-left: 17px;
}
.gy-info ul li {
  line-height: 1.9;
}
.gy-avatar {
  position: relative;
  border-radius: 50%;
  height: 28vw;
  width: 28vw;
  overflow: hidden;
  box-shadow: 0 0 6px #333
}
.gy-avatar img {
  width: 100%;
  position: absolute;
}
.top-img {
  width: 100%;
}
</style>